import { BrandedError } from './branded-error'

export class UnexpectedError extends BrandedError {
  brand = 'UnexpectedError' as const
  constructor(e: Error) {
    super(e.message, {
      cause: e,
    })
  }
}

export const toUnexpectedError = (e: unknown) => {
  if (e instanceof Error) {
    return new UnexpectedError(e)
  }
  return new UnexpectedError(
    new Error(`Unexpected error thrown as a literal: ${JSON.stringify(e, null, 2)}`),
  )
}
