import type { FC } from 'react'

import { Video } from '@src/domain/core/ui/components/video'

type DialogVideoProps = {
  srcMp4: string
  srcWebm: string
}

export const DialogVideoIcon: FC<DialogVideoProps> = ({ srcMp4, srcWebm }) => {
  return (
    <Video
      srcMp4={srcMp4}
      srcWebm={srcWebm}
      css={{ w: '8rem', h: '8rem', minW: '8rem', minH: '8rem' }}
    />
  )
}
