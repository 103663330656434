import { useAccount } from 'wagmi'

import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { useIsAccountBlocked } from '@src/domain/auth/hooks/use-client-status'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'
import { starkexAbi } from '@src/domain/starkex/abi/starkex'
import { useSelectedMarket } from '@src/domain/trade/store/market'

/**
 * This hook is only used to detect pending deposits for blocked accounts, so they can cancel and reclaim them.
 * Otherwise we rely on backend endpoints to get info about detected/processed etc. deposits and whatnot
 */
export const useDepositBalance = () => {
  const { address } = useAccount()
  const { starkExContractAddress } = useSettings()

  const accountInfo = useAccountInfo()
  const market = useSelectedMarket()
  const isAccountBlocked = useIsAccountBlocked()

  invariant(accountInfo, 'Account info is required to use this hook')

  return useReadContract({
    abi: starkexAbi,
    address: starkExContractAddress,
    functionName: 'getDepositBalance',
    args: [
      BigInt(accountInfo.l2Key),
      BigInt(market.l2Config.collateralId),
      BigInt(accountInfo.l2Vault),
    ],
    query: {
      enabled: Boolean(address) && Boolean(accountInfo),
      // we want to still call this one, to have info about pending deposits even for unblocked accounts to avoid race condition
      // for when account gets blocked and we don't have info about pending deposits and react to blocked account status in a wrong way.
      // For example, we should open Rejected Deposits dialog if balance is >0, but if we don't have info about pending deposits, we won't do that and open Reduce Only dialog instead.
      refetchInterval: isAccountBlocked ? 1_000 : 5_000,
    },
  })
}
