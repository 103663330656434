export abstract class BrandedError extends Error {
  abstract brand: unknown
}

export class UserRejectedCreationOfPrivateKeyError extends BrandedError {
  brand = 'UserRejectedCreationOfPrivateKeyError' as const

  constructor(message: string = 'User rejected creation of private key') {
    super(message)
  }
}

export class DelayedResolveTimeoutError<T> extends BrandedError {
  brand = 'DelayedResolveTimeoutError' as const
  operationResult: T

  constructor({ operationResult }: { operationResult: T }) {
    super('Delayed resolve operation timed out')
    this.operationResult = operationResult
  }
}

export class DelayedResolveComponentUnmountedError<T> extends BrandedError {
  brand = 'DelayedResolveComponentUnmountedError' as const
  operationResult?: T

  constructor({ operationResult }: { operationResult?: T }) {
    super('Component unmounted during delayed resolve operation')
    this.operationResult = operationResult
  }
}

export class WaitTimeoutError extends BrandedError {
  brand = 'WaitTimeoutError' as const

  constructor() {
    super('Wait failed due to timeout')
  }
}
