import { useCallback } from 'react'
import { err, fromPromise } from 'neverthrow'
import { BaseError } from 'viem'
import { useAccount, useWaitForTransactionReceipt } from 'wagmi'

import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useAccountStarkPrivateKey } from '@src/domain/auth/hooks/use-account-stark-private-key'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { UserRejectedCreationOfPrivateKeyError } from '@src/domain/core/errors/branded-error'
import { makeViemBaseError } from '@src/domain/core/errors/make-third-party-errors'
import { toUnexpectedError } from '@src/domain/core/errors/to-unexpected-error'
import { useWriteContract } from '@src/domain/core/hooks/use-write-contract'
import { starkexAbi } from '@src/domain/starkex/abi/starkex'
import { signRegisterSenderPayload } from '@src/domain/starkex/utils/sign-register-sender-payload'
import { useSelectedMarket } from '@src/domain/trade/store/market'

export const useDepositCancel = () => {
  const market = useSelectedMarket()
  const accountInfo = useAccountInfo()
  const { starkExContractAddress } = useSettings()
  const {
    writeContractAsync,
    isPending: isDepositCancelPending,
    data: txHash,
  } = useWriteContract()

  const { isSuccess: isConfirmed, isLoading: isConfirming } =
    useWaitForTransactionReceipt({
      hash: txHash,
    })

  const depositCancel = useCallback(async () => {
    invariant(accountInfo, 'No account info')
    return fromPromise(
      writeContractAsync({
        abi: starkexAbi,
        address: starkExContractAddress,
        functionName: 'depositCancel',
        args: [
          BigInt(accountInfo.l2Key),
          BigInt(market.l2Config.collateralId),
          BigInt(accountInfo.l2Vault),
        ],
      }),
      (e) => {
        if (e instanceof BaseError) {
          return makeViemBaseError(e)
        }
        return toUnexpectedError(e)
      },
    )
  }, [
    accountInfo,
    writeContractAsync,
    starkExContractAddress,
    market.l2Config.collateralId,
  ])

  return {
    depositCancel,
    isDepositCancelPending: isDepositCancelPending,
    isDepositCancelConfirming: isConfirming,
    isDepositCancelConfirmed: isConfirmed,
  }
}

export const useRegisterSender = () => {
  const accountInfo = useAccountInfo()
  const { address } = useAccount()
  const { starkExContractAddress } = useSettings()
  const { getStarkPrivateKey } = useAccountStarkPrivateKey()
  const {
    writeContractAsync,
    isPending: isRegisterSenderPending,
    data: txHash,
  } = useWriteContract()

  const { isSuccess: isConfirmed, isLoading: isConfirming } =
    useWaitForTransactionReceipt({
      hash: txHash,
    })

  const registerSender = useCallback(async () => {
    invariant(accountInfo, 'No L2 account info')
    invariant(address, 'No L1 address')
    const privateKey = await getStarkPrivateKey()
    if (privateKey.isErr()) {
      return err(new UserRejectedCreationOfPrivateKeyError())
    }

    const payload = signRegisterSenderPayload(
      {
        publicStarkKey: accountInfo.l2Key,
        publicL1Key: address,
      },
      privateKey.value,
    )

    return fromPromise(
      writeContractAsync({
        abi: starkexAbi,
        address: starkExContractAddress,
        functionName: 'registerSender',
        args: [BigInt(accountInfo.l2Key), payload],
      }),
      (e) => {
        if (e instanceof BaseError) {
          return makeViemBaseError(e)
        }
        return toUnexpectedError(e)
      },
    )
  }, [
    accountInfo,
    address,
    getStarkPrivateKey,
    starkExContractAddress,
    writeContractAsync,
  ])

  return {
    registerSender,
    isRegisterSenderPending: isRegisterSenderPending,
    isRegisterSenderConfirming: isConfirming,
    isRegisterSenderConfirmed: isConfirmed,
  }
}
