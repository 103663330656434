import { useEffect, useState } from 'react'
import { padStart } from 'lodash'

const formatDigit = (digit: number) => padStart(digit.toString(), 2, '0')

export const useCountdown = ({
  targetDate,
  hoursDisplay = 'always',
}: {
  targetDate: number
  hoursDisplay?: 'always' | 'except-zero'
}) => {
  const [countdown, setCountdown] = useState('')

  useEffect(() => {
    if (targetDate < Date.now()) {
      return
    }

    const calculateAndSetCountdown = () => {
      const now = new Date()
      const difference = targetDate - now.getTime()

      if (difference <= 0) {
        clearInterval(timer)
        setCountdown('')
      } else {
        let seconds = Math.floor(difference / 1000)
        let minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)

        minutes %= 60
        seconds %= 60

        let formattedCountdown = ''

        if ((hoursDisplay === 'except-zero' && hours > 0) || hoursDisplay === 'always') {
          formattedCountdown = `${formatDigit(hours)}:`
        }

        setCountdown(
          `${formattedCountdown}${formatDigit(minutes)}:${formatDigit(seconds)}`,
        )
      }
    }

    const timer = setInterval(calculateAndSetCountdown, 1000)

    calculateAndSetCountdown()

    return () => clearInterval(timer)
  }, [hoursDisplay, targetDate])

  return countdown
}
