import { FormattedMessage } from '@x10/lib-core/i18n'
import { Cell } from '@x10/lib-ui-kit/components'

import { useCountdown } from '@src/domain/core/hooks/use-countdown'

export const DepositRejectedCountdown = ({ targetDate }: { targetDate: number }) => {
  const countdown = useCountdown({ targetDate })

  if (!countdown) {
    return null
  }

  return (
    <Cell.Item
      css={{
        mt: '1.875rem',
      }}
    >
      <Cell.Title>
        <FormattedMessage
          id="workspace.trade.widget.account.deposit-rejected.dialog.claim-funds.title"
          defaultMessage="You can claim funds in"
        />
      </Cell.Title>

      <Cell.Value
        css={{
          color: 'dark.green',
        }}
      >
        {countdown}
      </Cell.Value>
    </Cell.Item>
  )
}
