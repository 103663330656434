import { Suspense, type FC, type ReactNode } from 'react'
import { useAccount } from 'wagmi'

type WalletConnectedWrapperProps = {
  children: ReactNode
}

const WalletConnectedWrapper: FC<WalletConnectedWrapperProps> = ({ children }) => {
  const { address } = useAccount()

  if (!address) {
    return null
  }

  return children
}

type WalletConnectedGuardProps = {
  children: ReactNode
  fallback?: ReactNode
}

export const WalletConnectedGuard: FC<WalletConnectedGuardProps> = ({
  children,
  fallback = null,
}) => (
  <Suspense fallback={fallback}>
    <WalletConnectedWrapper>{children}</WalletConnectedWrapper>
  </Suspense>
)
