import { useAccount } from 'wagmi'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'
import { starkexAbi } from '@src/domain/starkex/abi/starkex'
import { useSelectedMarket } from '@src/domain/trade/store/market'

export const useCancellationRequest = () => {
  const { address } = useAccount()

  const accountInfo = useAccountInfo()
  const market = useSelectedMarket()
  const { starkExContractAddress } = useSettings()

  return useReadContract({
    abi: starkexAbi,
    address: starkExContractAddress,
    functionName: 'getCancellationRequest',
    args: [
      // @ts-expect-error -- expected as we want to use this hook in unsafe context and ! hides override
      BigInt(accountInfo.l2Key),
      BigInt(market.l2Config.collateralId),
      // @ts-expect-error -- expected as we want to use this hook in unsafe context and ! hides override
      BigInt(accountInfo.l2Vault),
    ],
    query: {
      enabled: Boolean(address) && Boolean(accountInfo),
    },
  })
}
