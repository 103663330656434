import { useCallback, useEffect, useRef } from 'react'
import { match } from 'ts-pattern'

import { checkRequired, getLogger, invariant, uuid } from '@x10/lib-core/utils'

import { API } from '@src/domain/api/x10'
import {
  type CandlePriceSource,
  type MarketName,
  type X10Interval,
} from '@src/domain/api/x10/common'

const LOGGER = getLogger('app-exchange.core.use-web-socket')
const RECONNECT_TIMEOUT_MS = 1000

type Topic =
  | {
      name: 'ACCOUNT_UPDATES'
      account: string
    }
  | {
      name: 'ORDERBOOKS'
      marketName: MarketName
    }
  | {
      name: 'PUBLIC_TRADES'
      marketName: MarketName
    }
  | {
      name: 'CANDLES'
      source: CandlePriceSource
      marketName: MarketName
      interval: X10Interval
    }

const getTopicUrl = (topic: Topic): string => {
  return match(topic)
    .with({ name: 'ORDERBOOKS' }, ({ marketName }) => {
      return API.stream.orderBooks(marketName)
    })
    .with({ name: 'PUBLIC_TRADES' }, ({ marketName }) => {
      return API.stream.publicTrades(marketName)
    })
    .with({ name: 'CANDLES' }, ({ source, marketName, interval }) => {
      return API.stream.candles(source, marketName, interval)
    })
    .with({ name: 'ACCOUNT_UPDATES' }, ({ account }) => {
      return API.stream.accountUpdates({ account })
    })
    .exhaustive()
}

const addAuthToken = (url: string, token: string | undefined): string => {
  if (!token) {
    return url
  }

  return url.includes('?') ? `${url}&token=${token}` : `${url}?token=${token}`
}

export const useWebSocket = () => {
  const connectionsRef = useRef(new Map<string, WebSocket>())

  const subscribe = useCallback(
    async (args: {
      topic: Topic
      callback: (event: MessageEvent) => void
      subscriberUid?: string
      getWsToken?: () => Promise<string>
      /**
       * If set, events will be ignored for the specified amount of milliseconds
       * (avoid notifications on snapshot)
       */
      ignoreEventsForMs?: number
    }) => {
      const topicUrl = getTopicUrl(args.topic)
      const subscriberUid = args.subscriberUid ?? uuid()
      const connectionName = `${subscriberUid}:${topicUrl}`

      const connect = async () => {
        invariant(
          !connectionsRef.current.has(subscriberUid),
          `Connection "${connectionName}" is already opened`,
        )

        LOGGER.debug('Connecting: %s', connectionName)

        let wsToken: string | undefined

        try {
          wsToken = await args.getWsToken?.()
        } catch (error) {
          LOGGER.debug('Error on getting auth token, closing: %s', connectionName)

          connectionsRef.current.delete(subscriberUid)
          return
        }

        const ws = new WebSocket(addAuthToken(topicUrl, wsToken))

        let isIgnoringEvents = args.ignoreEventsForMs !== undefined

        setTimeout(() => {
          isIgnoringEvents = false
        }, args.ignoreEventsForMs ?? 0)

        ws.onmessage = (event) => {
          !isIgnoringEvents && args.callback(event)
        }

        connectionsRef.current.set(subscriberUid, ws)
      }

      const reconnectIfClosed = async () => {
        const ws = connectionsRef.current.get(subscriberUid)

        if (!ws) {
          return
        }

        // WebSocket was closed unexpectedly: we still have it in the map, but it's closed
        if (ws.readyState === WebSocket.CLOSED) {
          LOGGER.debug('Reconnecting: %s', connectionName)

          connectionsRef.current.delete(subscriberUid)
          await connect()
        }

        setTimeout(reconnectIfClosed, RECONNECT_TIMEOUT_MS)
      }

      await connect()
      await reconnectIfClosed()

      return subscriberUid
    },
    [],
  )

  const unsubscribe = useCallback((subscriberUid: string) => {
    if (!connectionsRef.current.has(subscriberUid)) {
      LOGGER.debug('Connection for subscriber %s is already closed', subscriberUid)

      return
    }

    const ws = checkRequired(connectionsRef.current.get(subscriberUid), 'ws')
    const connectionName = `${subscriberUid}:${ws.url}`

    LOGGER.debug('Closing [%s]: %s', ws.readyState, connectionName)

    ws.close()
    connectionsRef.current.delete(subscriberUid)
  }, [])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Suppress `if this ref points to a node rendered by React` warning
      const topics = Array.from(connectionsRef.current.keys())

      topics.forEach((topicUrl) => {
        unsubscribe(topicUrl)
      })
    }
  }, [unsubscribe])

  return { subscribe, unsubscribe }
}
