import { useEffect } from 'react'
import { create } from 'zustand'

import { useSuspendedClientInfo } from '@src/domain/api/hooks/account/use-client-info'
import { usePositions } from '@src/domain/api/hooks/account/use-positions'
import { addDelayBeforeNextStep } from '@src/domain/core/utils/add-delay-before-next-step'
import { useDepositBalance } from '@src/domain/starkex/hooks/use-deposit-balance'

const useClientStatusState = create<'ACTIVE' | 'BLOCKED'>(() => {
  return 'ACTIVE'
})

export const openReduceOnlyDialog = () => {
  useBlockedAccountDialogsState.setState((state) => {
    if (!state.isWithdrawalRejectedDialogOpen && !state.isDepositRejectedDialogOpen) {
      return {
        isReduceOnlyDialogOpen: true,
      }
    }
    return state
  })
}

export const useBlockedAccountDialogsState = create<{
  isReduceOnlyDialogOpen: boolean
  isWithdrawalRejectedDialogOpen: boolean
  isDepositRejectedDialogOpen: boolean
}>(() => {
  return {
    isReduceOnlyDialogOpen: false,
    isWithdrawalRejectedDialogOpen: false,
    isDepositRejectedDialogOpen: false,
  }
})

export const openWithdrawalRejectedDialog = () => {
  useBlockedAccountDialogsState.setState((state) => {
    if (!state.isReduceOnlyDialogOpen) {
      return {
        isWithdrawalRejectedDialogOpen: true,
      }
    }
    return state
  })
}

export const closeWithdrawalRejectedDialog = () => {
  useBlockedAccountDialogsState.setState({
    isWithdrawalRejectedDialogOpen: false,
  })
  addDelayBeforeNextStep(() => {
    useBlockedAccountDialogsState.setState({
      isReduceOnlyDialogOpen: true,
    })
  })
}

export const openDepositRejectedDialog = () => {
  useBlockedAccountDialogsState.setState((state) => {
    if (!state.isReduceOnlyDialogOpen) {
      return {
        isDepositRejectedDialogOpen: true,
      }
    }
    return state
  })
}

export const closeDepositRejectedDialog = () => {
  useBlockedAccountDialogsState.setState({
    isDepositRejectedDialogOpen: false,
  })
  addDelayBeforeNextStep(() => {
    useBlockedAccountDialogsState.setState({
      isReduceOnlyDialogOpen: true,
    })
  })
}

export const closeReduceOnlyDialog = () => {
  useBlockedAccountDialogsState.setState(() => {
    return {
      isReduceOnlyDialogOpen: false,
    }
  })
}

export const usePollClientStatusState = () => {
  const clientInfo = useSuspendedClientInfo()

  useEffect(() => {
    useClientStatusState.setState(clientInfo.status)
    return () => {
      useClientStatusState.setState('ACTIVE') // resetting back to active
    }
  }, [clientInfo.status])
}

export const useIsAccountBlocked = () => {
  return useClientStatusState() === 'BLOCKED'
}

export const useIsAccountBlockedWithPendingDeposit = () => {
  const { data: pendingDepositsBalance } = useDepositBalance()
  return useClientStatusState() === 'BLOCKED' && (pendingDepositsBalance ?? 0n) > 0n
}

export const useIsTradingDisabled = () => {
  const { data: positions } = usePositions()
  const hasOpenPositions = positions.length > 0
  const isAccountBlocked = useIsAccountBlocked()
  return isAccountBlocked && !hasOpenPositions
}

export const useListenToBlockedUpdatesAndOpenDialogs = () => {
  const isAccountBlockedWithPendingDeposit = useIsAccountBlockedWithPendingDeposit()
  const isAccountBlocked = useIsAccountBlocked()
  const { isLoading: isBalanceLoading } = useDepositBalance()

  useEffect(() => {
    if (isAccountBlockedWithPendingDeposit) {
      openDepositRejectedDialog()
    } else if (!isBalanceLoading && isAccountBlocked) {
      openReduceOnlyDialog()
    }
  }, [isAccountBlocked, isAccountBlockedWithPendingDeposit, isBalanceLoading])
}
