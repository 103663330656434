import BN from 'bn.js'
import { encodePacked, keccak256 } from 'viem'
import {
  ec as starkEc,
  sign as starkSign,
} from '@starkware-industries/starkware-crypto-utils'

import type { HexString } from '@x10/lib-core/types'
import { fromHexString, invariant, toHexString } from '@x10/lib-core/utils'

/**
 * Signs L1 public key over L2 (StarkEx) public key using StarkEx private key after some hashing.
 * More details what exactly happens in terms of cryptography etc. can be found in a smart contract code:
 * https://github.com/starkware-libs/starkex-contracts/blob/master/scalable-dex/contracts/src/components/Users.sol#L57
 */
export const signRegisterSenderPayload = (
  {
    publicStarkKey,
    publicL1Key,
  }: {
    publicStarkKey: HexString
    publicL1Key: HexString
  },
  starkPrivateKey: HexString,
) => {
  const encoded = encodePacked(
    ['string', 'address', 'uint256'],
    ['UserRegistration:', publicL1Key, BigInt(publicStarkKey)],
  )
  const hash = keccak256(encoded)
  const hashBn = new BN(fromHexString(hash), 'hex')

  invariant(starkEc.n, 'No n in starkEc')
  const modAgainstStarkExElliptic = hashBn.mod(starkEc.n)

  const starkPrivateKeyWithoutPrefix = fromHexString(starkPrivateKey)
  const starkKeyPair = starkEc.keyFromPrivate(starkPrivateKeyWithoutPrefix, 'hex')
  const signature = starkSign(starkKeyPair, modAgainstStarkExElliptic.toString('hex'))

  const starkYKey = starkKeyPair.getPublic().getY().toJSON()
  return encodePacked(
    ['uint256', 'uint256', 'uint256'],
    [
      BigInt(toHexString(signature.r.toJSON())),
      BigInt(toHexString(signature.s.toJSON())),
      BigInt(toHexString(starkYKey)),
    ],
  )
}
