import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'
import { starkexAbi } from '@src/domain/starkex/abi/starkex'

/**
 * Should/expected be used inside DepositRejectedDialog only and in logged in context
 * otherwise it will error out.
 */
export const useEthKey = () => {
  const { starkExContractAddress } = useSettings()

  const accountInfo = useAccountInfo()
  invariant(accountInfo, 'No account info')
  return useReadContract({
    abi: starkexAbi,
    address: starkExContractAddress,
    functionName: 'getEthKey',
    args: [BigInt(accountInfo.l2Key)],
    query: {
      enabled: Boolean(accountInfo.l2Key),
    },
  })
}
