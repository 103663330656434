import { sortBy } from 'lodash'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import {
  removeRestEnvelope,
  removeRestEnvelopeSuspense,
} from '@src/domain/api/utils/remove-rest-envelope'
import { API, type UserPosition } from '@src/domain/api/x10'
import type { MarketName, PositionSide } from '@src/domain/api/x10/common'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

const PLACEHOLDER_DATA: UserPosition[] = []

export const usePositions = ({
  marketsNames,
  side,
}: { marketsNames?: MarketName[]; side?: PositionSide } = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data } = useQuery({
    queryKey: [QueryKey.UserPositions, sortedMarketsNames, side, accountId],
    queryFn: () => {
      return API.trading.account.getPositions(
        {
          marketsNames: sortedMarketsNames,
          side,
        },
        accountId,
      )
    },
    enabled: Boolean(accountId),
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelope(data) ?? PLACEHOLDER_DATA,
  }
}

export const usePositionsSuspense = ({
  marketsNames,
  side,
}: { marketsNames?: MarketName[]; side?: PositionSide } = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserPositions, sortedMarketsNames, side, accountId],
    queryFn: () => {
      return API.trading.account.getPositions(
        {
          marketsNames: sortedMarketsNames,
          side,
        },
        accountId,
      )
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
