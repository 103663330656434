// eslint-disable-next-line @typescript-eslint/no-explicit-any -- it's ok tricky code to type
function getPrototypeNames(obj: any) {
  const prototypeNames = new Set<string>(
    [obj.constructor?.name, typeof obj?.name === 'string' && obj.name].filter(Boolean),
  )

  // Traverse the prototype chain and name fields
  while ((obj = Object.getPrototypeOf(obj))) {
    const constructorName = obj.constructor?.name
    constructorName && prototypeNames.add(constructorName)
    const name = (typeof obj?.name === 'string' && obj.name)?.name
    name && prototypeNames.add(name)
  }

  return prototypeNames
}

export const toBrandedError = <Brand extends string, T extends Error>(
  brand: Brand,
  e: T,
) => {
  const prototypeNames = getPrototypeNames(e)

  if (prototypeNames.has(brand)) {
    // @ts-expect-error -- it's ok tricky code to type
    e.brand = brand
    // This is unlikely as Error always has a name, but we will patch it just in case
    if (e.name === undefined) {
      e.name = e.constructor.name
    }
    return e as T & { brand: Brand; name: string }
  }
  const protoNames = Array.from(prototypeNames).filter((it) => it !== 'Object')

  throw new Error(
    `Developer mistake while branding error: Expected '${brand}', but got [${protoNames.join(', ')}]`,
  )
}
