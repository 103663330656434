import { useEffect, useRef, useState, type FC } from 'react'
import { omit } from 'lodash'

import { css } from '@x10/lib-styled-system/css'
import { Flex, styled, type FlexProps } from '@x10/lib-styled-system/jsx'
import { Spinner } from '@x10/lib-ui-kit/components'

const PROGRESS_INTERVAL_MS = 100
const SPINNER_DELAY_MS = 400
const SHOW_DELAY_MS = 200

type VideoState = 'initial' | 'buffering' | 'canplay' | 'playing' | 'ended'

type CommonProps = Omit<
  FlexProps,
  'src' | 'autoPlay' | 'loop' | 'onProgress' | 'onEnded' | 'children'
> & {
  autoPlay?: boolean
  loop?: boolean
  playbackRate?: number
  onProgress?: (progress: number) => void
  onEnded?: () => void
}

// rest of the cases, use mp4, as webm isn't supported by Safari
type SrcProps = { src: string } & CommonProps

// alpha channel case https://rotato.app/blog/transparent-videos-for-the-web
type SourceChildrenProps = { srcWebm: string; srcMp4: string } & CommonProps
type VideoProps = SrcProps | SourceChildrenProps

export const Video: FC<VideoProps> = ({
  autoPlay = true,
  loop = true,
  playbackRate,
  onProgress,
  onEnded,
  ...restProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoState, setVideoState] = useState<VideoState>('initial')
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const api = videoRef.current

    if (!api) {
      return
    }

    if (playbackRate) {
      api.playbackRate = playbackRate
    }

    let spinnerTimerId = setTimeout(() => {
      setVideoState((prevState) => {
        return prevState === 'initial' ? 'buffering' : prevState
      })
    }, SPINNER_DELAY_MS)

    const handleCanPlay = () => {
      clearTimeout(spinnerTimerId)
      setVideoState('canplay')
    }

    const handleWaiting = () => {
      clearTimeout(spinnerTimerId)
      spinnerTimerId = setTimeout(() => {
        setVideoState('buffering')
      }, SPINNER_DELAY_MS)
    }

    const handlePlaying = () => {
      clearTimeout(spinnerTimerId)
      setVideoState('playing')
    }

    const handleEnded = () => {
      onEnded?.()
      setVideoState('ended')
      setVisible(false)
    }

    const progressTimerId = setInterval(() => {
      const value = api.duration > 0 ? api.currentTime / api.duration : 0
      onProgress?.(value)
    }, PROGRESS_INTERVAL_MS)

    api.addEventListener('canplay', handleCanPlay)
    api.addEventListener('waiting', handleWaiting)
    api.addEventListener('playing', handlePlaying)
    api.addEventListener('ended', handleEnded)

    return () => {
      setVisible(false)

      clearInterval(progressTimerId)
      clearTimeout(spinnerTimerId)

      api.removeEventListener('canplay', handleCanPlay)
      api.removeEventListener('waiting', handleWaiting)
      api.removeEventListener('playing', handlePlaying)
      api.removeEventListener('ended', handleEnded)
    }
  }, [playbackRate, onProgress, onEnded, setVideoState])

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, SHOW_DELAY_MS)
  }, [])

  useEffect(() => {
    const api = videoRef.current

    if (!api) {
      return
    }

    if (autoPlay) {
      api.play().then(() => setVideoState('playing'))
    }
  }, [autoPlay])

  // @ts-expect-error -- TS is tripping here saying union type is too hard to represent :)
  const filteredProps: Omit<CommonProps, 'onProgress'> = omit(restProps, [
    'srcMp4',
    'srcWebm',
    'src',
    'onProgress',
  ])

  return (
    <Flex
      data-scope="video"
      {...filteredProps}
      css={css.raw(
        {
          flex: 1,
          maxH: '100%',
          overflow: 'hidden',
          position: 'relative',
        },
        restProps.css,
      )}
    >
      <styled.video
        ref={videoRef}
        muted
        autoPlay={autoPlay}
        playsInline
        disableRemotePlayback
        disablePictureInPicture
        loop={loop}
        src={'src' in restProps ? restProps.src : undefined}
        css={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.5s',
          flex: 1,
        }}
      >
        {'srcMp4' in restProps ? (
          <>
            <source src={restProps.srcMp4} type='video/mp4; codecs="hvc1"' />
            <source src={restProps.srcWebm} type="video/webm" />
          </>
        ) : null}
      </styled.video>

      {videoState === 'buffering' && (
        <Spinner
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
        />
      )}
    </Flex>
  )
}
