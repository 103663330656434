import type { Abi, ContractFunctionArgs, ContractFunctionName } from 'viem'
import {
  useReadContract as useReadContractWagmi,
  type UseReadContractParameters,
} from 'wagmi'
import type { UseReadContractReturnType } from 'wagmi'
import type { Config, ResolvedRegister } from '@wagmi/core'
import type { ReadContractData } from '@wagmi/core/query'

import { getChain } from '@src/domain/auth/providers/wagmi-provider'

export function useReadContract<
  const abi extends Abi | readonly unknown[],
  functionName extends ContractFunctionName<abi, 'pure' | 'view'>,
  args extends ContractFunctionArgs<abi, 'pure' | 'view', functionName>,
  config extends Config = ResolvedRegister['config'],
  selectData = ReadContractData<abi, functionName, args>,
>(
  parameters: UseReadContractParameters<
    abi,
    functionName,
    args,
    config,
    selectData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- lib code
  > = {} as any,
): UseReadContractReturnType<abi, functionName, args, selectData> {
  // @ts-expect-error -- unsure how to fix, but types seem correct
  return useReadContractWagmi({ chainId: getChain().id, ...parameters })
}
