import { useCallback } from 'react'
import { err, ok } from 'neverthrow'
import { BaseError } from 'viem'

import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { makeViemBaseError } from '@src/domain/core/errors/make-third-party-errors'
import { toUnexpectedError } from '@src/domain/core/errors/to-unexpected-error'
import { useWriteContract } from '@src/domain/core/hooks/use-write-contract'
import { starkexAbi } from '@src/domain/starkex/abi/starkex'
import { useSelectedMarket } from '@src/domain/trade/store/market'

export const useDepositReclaim = () => {
  const market = useSelectedMarket()

  const { writeContractAsync, isPending: isDepositReclaimPending } = useWriteContract()
  const accountInfo = useAccountInfo()
  const { starkExContractAddress } = useSettings()

  const depositReclaim = useCallback(async () => {
    invariant(accountInfo, 'No account info')
    try {
      const result = await writeContractAsync({
        abi: starkexAbi,
        address: starkExContractAddress,
        functionName: 'depositReclaim',
        args: [
          BigInt(accountInfo.l2Key),
          BigInt(market.l2Config.collateralId),
          BigInt(accountInfo.l2Vault),
        ],
      })
      return ok(result)
    } catch (e) {
      if (e instanceof BaseError) {
        return err(makeViemBaseError(e))
      }
      return err(toUnexpectedError(e))
    }
  }, [
    accountInfo,
    writeContractAsync,
    starkExContractAddress,
    market.l2Config.collateralId,
  ])

  return { depositReclaim, isDepositReclaimPending }
}
