import { useCallback } from 'react'
import {
  useConfig,
  useWriteContract as useWriteContractWagmi,
  type Config,
  type UseWriteContractParameters,
} from 'wagmi'
import type { UseWriteContractReturnType } from 'wagmi'
import { getAccount, switchChain, type ResolvedRegister } from '@wagmi/core'

import { getChain } from '@src/domain/auth/providers/wagmi-provider'

export function useWriteContract<
  config extends Config = ResolvedRegister['config'],
  context = unknown,
>(
  parameters: UseWriteContractParameters<config, context> = {},
): UseWriteContractReturnType<config, context> {
  const wagmiConfig = useConfig()
  const writeContractReturn = useWriteContractWagmi<config, context>(parameters)

  const { writeContractAsync, writeContract } = writeContractReturn
  writeContractReturn.writeContractAsync = useCallback(
    async (variables, options) => {
      variables.chainId = getChain().id
      if (getAccount(wagmiConfig).chainId !== getChain().id) {
        await switchChain(wagmiConfig, {
          chainId: getChain().id,
        })
      }

      return writeContractAsync(variables, options)
    },
    [wagmiConfig, writeContractAsync],
  )

  writeContractReturn.writeContract = useCallback(
    (variables, options) => {
      variables.chainId = getChain().id
      if (getAccount(wagmiConfig).chainId !== getChain().id) {
        switchChain(wagmiConfig, {
          chainId: getChain().id,
        })
      }

      return writeContract(variables, options)
    },
    [wagmiConfig, writeContract],
  )

  return writeContractReturn as ReturnType<typeof useWriteContractWagmi<config, context>>
}
