import { useCallback, useEffect, useState } from 'react'
import { addDays, differenceInHours } from 'date-fns'
import { useAccount } from 'wagmi'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl, getLogger } from '@x10/lib-core/utils'
import { styled, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Dialog,
  Portal,
  Step,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import {
  closeDepositRejectedDialog,
  useBlockedAccountDialogsState,
} from '@src/domain/auth/hooks/use-client-status'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { DialogImageIcon } from '@src/domain/core/ui/components/dialog/dialog-image-icon'
import { dismissNotificationToast } from '@src/domain/core/ui/components/notification'
import { GlobalToastIds } from '@src/domain/core/ui/components/notification/notification-toast'
import { useDepositCancel, useRegisterSender } from '@src/domain/starkex/hooks/kyt'
import { useCancellationRequest } from '@src/domain/starkex/hooks/use-cancellation-request'
import { useDepositReclaim } from '@src/domain/starkex/hooks/use-deposit-reclaim'
import { useEthKey } from '@src/domain/starkex/hooks/use-eth-key'

import { DepositRejectedCountdown } from './deposit-rejected-countdown'

const convertToMs = (seconds: bigint) => Number(seconds * 1000n)

/**
 * Checks if 2 days have passed since the given Solidity timestamp (seconds from Unix epoch).
 * @param {bigint} ethTimestamp - The timestamp read from the smart contract (in seconds since Unix epoch)
 * @returns {boolean} - True if 2 days have passed, false otherwise
 */
export function haveTwoDaysPassed(ethTimestamp: bigint) {
  const timestampInMilliseconds = convertToMs(ethTimestamp)

  const TWO_DAYS = 48
  return (
    differenceInHours(Date.now(), timestampInMilliseconds, { roundingMethod: 'floor' }) >=
    TWO_DAYS
  )
}

const LOGGER = getLogger('app-exchange.domain.trade.deposit-rejected-dialog')

export const DepositRejectedDialog = () => {
  const isDepositRejectedDialogOpen = useBlockedAccountDialogsState(
    (it) => it.isDepositRejectedDialogOpen,
  )

  const { address } = useAccount()
  const { data: ethKey, isPending: isEthKeyPending, refetch: refetchEthKey } = useEthKey()

  const {
    data: cancellationRequestBlockTimestamp,
    isPending: isCancellationRequestBlockTimestampPending,
    refetch: refetchCancellationRequestBlockTimestamp,
  } = useCancellationRequest()
  const {
    registerSender,
    isRegisterSenderPending,
    isRegisterSenderConfirming,
    isRegisterSenderConfirmed,
  } = useRegisterSender()
  const {
    depositCancel,
    isDepositCancelPending,
    isDepositCancelConfirming,
    isDepositCancelConfirmed,
  } = useDepositCancel()
  const { depositReclaim, isDepositReclaimPending } = useDepositReclaim()

  const [currentStep, setCurrentStep] = useState<
    | 'INIT'
    | 'REGISTER_ETH'
    | 'REGISTER_IN_PROGRESS'
    | 'CANCEL_DEPOSIT'
    | 'CANCEL_DEPOSIT_IN_PROGRESS'
    | 'RECLAIM_PENDING'
    | 'RECLAIM_IN_PROGRESS'
    | 'RECLAIM'
  >('INIT')

  const [isDepositReclaimed, setIsDepositReclaimed] = useState(false)
  const isEthKeyRegistered = ethKey === address
  const isDepositCancellationTriggered = !!cancellationRequestBlockTimestamp
  const isHaveTwoDaysPassed = cancellationRequestBlockTimestamp
    ? isDepositCancellationTriggered &&
      haveTwoDaysPassed(cancellationRequestBlockTimestamp)
    : false

  const isLoading =
    isEthKeyPending ||
    isCancellationRequestBlockTimestampPending ||
    isRegisterSenderPending ||
    isDepositCancelPending ||
    isDepositReclaimPending

  const ethKeyStatus =
    ethKey === address || isRegisterSenderConfirmed
      ? 'SUCCESS'
      : isRegisterSenderConfirming ||
          isRegisterSenderPending ||
          isEthKeyPending ||
          currentStep === 'REGISTER_IN_PROGRESS'
        ? 'PENDING'
        : undefined

  const cancellationRequestStatus =
    cancellationRequestBlockTimestamp || isDepositCancelConfirmed
      ? 'SUCCESS'
      : isDepositCancelConfirming ||
          isDepositCancelPending ||
          isCancellationRequestBlockTimestampPending ||
          currentStep === 'CANCEL_DEPOSIT_IN_PROGRESS'
        ? 'PENDING'
        : undefined

  const reclaimStatus = isDepositReclaimed
    ? 'SUCCESS'
    : isDepositReclaimPending ||
        isCancellationRequestBlockTimestampPending ||
        currentStep === 'RECLAIM_IN_PROGRESS'
      ? 'PENDING'
      : undefined

  // Set the initial state of the "state machine" after initial fetches are done
  useEffect(() => {
    if (!isEthKeyRegistered && !isRegisterSenderConfirmed) {
      setCurrentStep('REGISTER_ETH')
      return
    }
    if (!isDepositCancellationTriggered && !isDepositCancelConfirmed) {
      setCurrentStep('CANCEL_DEPOSIT')
      return
    }
    if (isHaveTwoDaysPassed && !isDepositReclaimed) {
      setCurrentStep('RECLAIM')
    } else {
      setCurrentStep('RECLAIM_PENDING')
    }
  }, [
    isEthKeyRegistered,
    isDepositCancellationTriggered,
    isHaveTwoDaysPassed,
    isRegisterSenderConfirmed,
    isDepositCancelConfirmed,
    isDepositReclaimed,
  ])

  const registerSenderFlow = useCallback(async () => {
    setCurrentStep('REGISTER_IN_PROGRESS')
    const res = await registerSender()
    if (res.isErr()) {
      setCurrentStep('REGISTER_ETH')
      const error = res.error
      if (error.brand === 'UnexpectedError') {
        captureExceptionWithSentry(error.cause)
      }
      return
    }
  }, [registerSender])

  const depositCancelFlow = useCallback(async () => {
    setCurrentStep('CANCEL_DEPOSIT_IN_PROGRESS')
    const res = await depositCancel()
    if (res.isErr()) {
      setCurrentStep('CANCEL_DEPOSIT')
      const error = res.error
      if (error.brand === 'UnexpectedError') {
        captureExceptionWithSentry(error.cause)
      }
      return
    }
  }, [depositCancel])

  const claimFlow = useCallback(async () => {
    setCurrentStep('RECLAIM_IN_PROGRESS')
    const res = await depositReclaim()
    if (res.isErr()) {
      setCurrentStep('RECLAIM')
      const error = res.error
      if (error.brand === 'UnexpectedError') {
        captureExceptionWithSentry(error.cause)
      }
      return
    }
    setIsDepositReclaimed(true)
    setTimeout(() => {
      closeDepositRejectedDialog()
      dismissNotificationToast(GlobalToastIds.ReclaimPendingDeposit)
    }, 1500)
  }, [depositReclaim])

  // Update eth key after register sender transaction is confirmed
  useEffect(() => {
    if (isRegisterSenderConfirmed) {
      refetchEthKey()
      setCurrentStep('CANCEL_DEPOSIT')
    }
  }, [isRegisterSenderConfirmed, refetchEthKey])

  // Update cancel block timestamp after register deposit cancellation is confirmed
  // to show timer for reclaim.
  useEffect(() => {
    if (isDepositCancelConfirmed) {
      refetchCancellationRequestBlockTimestamp()
      setCurrentStep('RECLAIM_PENDING')
    }
  }, [isDepositCancelConfirmed, refetchCancellationRequestBlockTimestamp])

  // Trigger depositCancelFlow right after registerSenderFlow is confirmed
  useEffect(() => {
    if (isRegisterSenderConfirmed && !isDepositCancelConfirmed) {
      depositCancelFlow()
    }
  }, [depositCancelFlow, isDepositCancelConfirmed, isRegisterSenderConfirmed])

  const startFlow = useCallback(() => {
    switch (currentStep) {
      case 'REGISTER_ETH':
        registerSenderFlow()
        break
      case 'CANCEL_DEPOSIT':
        depositCancelFlow()
        break
      case 'RECLAIM_PENDING':
        closeDepositRejectedDialog()
        break
      case 'RECLAIM':
        claimFlow()
        break
      default:
        LOGGER.warn('Unexpected step in deposit rejected dialog')
    }
  }, [claimFlow, currentStep, depositCancelFlow, registerSenderFlow])

  const interactionInProgress =
    isLoading || isDepositCancelConfirming || isRegisterSenderConfirming

  return (
    <Dialog.Root
      modal
      lazyMount
      unmountOnExit
      closeOnEscape={false}
      closeOnInteractOutside={false}
      open={isDepositRejectedDialogOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen.open) {
          closeDepositRejectedDialog()
        }
      }}
    >
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <DialogContent>
            <Dialog.CloseTrigger asChild>
              <ActionIcon
                disabled={interactionInProgress}
                css={{
                  top: 's-16',
                  right: 's-16',
                  pos: 'absolute',
                }}
              >
                <SvgIcon.SvgIconCross />
              </ActionIcon>
            </Dialog.CloseTrigger>
            <Dialog.Title>
              <FormattedMessage
                id="workspace.trade.widget.account.deposit-rejected.dialog.info-title"
                defaultMessage="Deposit Rejected"
              />
            </Dialog.Title>
            <VStack
              css={{
                pt: 's-56',
              }}
            >
              <DialogImageIcon
                src={getAssetUrl({
                  type: '3d-icon',
                  name: 'safe-warning@2x',
                })}
              />
              <styled.p
                css={{
                  textAlign: 'center',
                  textStyle: 'secondary',
                  mt: 's-16',
                }}
              >
                <FormattedMessage
                  id="workspace.trade.widget.account.deposit-rejected.dialog.info-text"
                  defaultMessage="Due to internal risk controls, your deposit has been rejected. To claim the funds:"
                />
              </styled.p>
              <Step.Root
                css={{
                  mt: 's-32',
                  gap: 's-32',
                  w: '100%',
                }}
              >
                <Step.Item>
                  <Step.Icon status={ethKeyStatus}>1</Step.Icon>
                  <Step.Content>
                    <Step.Title>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step1.title"
                        defaultMessage="Register key"
                      />
                    </Step.Title>
                    <Step.Description>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step1.description"
                        defaultMessage="Linking your L1 wallet with the L2 key to verify ownership."
                      />
                    </Step.Description>
                  </Step.Content>
                </Step.Item>

                <Step.Item>
                  <Step.Icon status={cancellationRequestStatus}>2</Step.Icon>
                  <Step.Content>
                    <Step.Title>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step2.title"
                        defaultMessage="Cancel deposit"
                      />
                    </Step.Title>
                    <Step.Description>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step2.description"
                        defaultMessage="Releasing the funds takes 48 hours before they are ready for claim."
                      />
                    </Step.Description>
                  </Step.Content>
                </Step.Item>

                <Step.Item>
                  <Step.Icon status={reclaimStatus}>3</Step.Icon>
                  <Step.Content>
                    <Step.Title>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step3.title"
                        defaultMessage="Claim deposit"
                      />
                    </Step.Title>
                    <Step.Description>
                      <FormattedMessage
                        id="workspace.trade.widget.account.deposit-rejected.dialog.step3.description"
                        defaultMessage="Withdrawing funds back to the L1 wallet."
                      />
                    </Step.Description>
                  </Step.Content>
                </Step.Item>
              </Step.Root>
            </VStack>
            {cancellationRequestBlockTimestamp && (
              <DepositRejectedCountdown
                targetDate={addDays(
                  convertToMs(cancellationRequestBlockTimestamp),
                  2,
                ).getTime()}
              />
            )}
            <Button
              visual="primary-green"
              size="large"
              loading={interactionInProgress}
              css={{
                mt: '1.875rem',
              }}
              onClick={startFlow}
            >
              {isHaveTwoDaysPassed ? (
                <FormattedMessage id="common.action.claim" defaultMessage="Claim" />
              ) : isDepositCancellationTriggered ? (
                <FormattedMessage id="common.action.close" defaultMessage="Close" />
              ) : (
                <FormattedMessage
                  id="workspace.trade.widget.account.deposit-rejected.dialog.action.proceed"
                  defaultMessage="Proceed"
                />
              )}
            </Button>
          </DialogContent>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
