import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import {
  removeRestEnvelope,
  removeRestEnvelopeSuspense,
} from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { useAccountId, useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useBalance = ({ accountId }: { accountId?: string } = {}) => {
  const isLoggedIn = useSuspendedIsLoggedIn()
  const currentAccountId = useAccountId()

  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.UserBalance, accountId ?? currentAccountId],
    queryFn: () => API.trading.account.getBalance(accountId ?? currentAccountId),
    enabled: isLoggedIn,
    ...getCommonPeriodicQueryConfig(),
  })

  return { data: removeRestEnvelope(data), isLoading }
}

export const useSuspendedBalance = ({ accountId }: { accountId?: string } = {}) => {
  const currentAccountId = useAccountId()

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserBalance, accountId ?? currentAccountId],
    queryFn: () => API.trading.account.getBalance(accountId ?? currentAccountId),
    ...getCommonPeriodicQueryConfig(),
  })

  return removeRestEnvelopeSuspense(data)
}
